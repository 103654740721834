<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="800"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title v-if="mode == 'Repeat'">{{ $t('Регистрация_повторного_обращения') }}</v-toolbar-title>
                <v-toolbar-title v-else>{{ $t('Регистрация_дубликата_обращения') }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                v-if="loading"
            >
                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Обращение") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-textarea
                                    class="oneRowStartTextarea"
                                    rows="1"
                                    auto-grow
                                    row-height="16"
                                    no-resize
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    readonly
                                    :value="originStatement"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Признак_повторного_или_дубликата") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    v-model.number="duplicateValue"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    type="number"
                                    class="cust-inputnumber"
                                    :min="1"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row v-if="mode == 'Repeat'" no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Причина_повторного") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-autocomplete
                                    v-model="duplicateReason"
                                    :items="GetReference(5200)"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    return-object
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Уникальный_номер") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                
                                <v-radio-group
                                    v-model="uniqueNumberParam"
                                    dense
                                    mandatory
                                    hide-details
                                    class="radio-group-full-width"
                                >
                                    <v-radio
                                        :label="$t('Без_номера')"
                                        :value="0"
                                    ></v-radio>
                                    <v-radio 
                                        :label="$t('Номер_по_порядку')"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio 
                                        :value="2"
                                    >
                                        <template v-slot:label>
                                            <v-col
                                                cols="4" sm="4" md="4"
                                                class="ma-0 pa-0"
                                            >
                                                {{ $t('Почтовый_номер') }}
                                            </v-col>
                                            <v-col
                                                cols="8" sm="8" md="8"
                                                class="ma-0 pa-0"
                                            >
                                                <v-text-field
                                                    v-if="uniqueNumberParam === 2"
                                                    v-model.trim="postUniqueNumber"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    maxlength="13"
                                                ></v-text-field>
                                            </v-col>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                    
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="!loading && valid"
                >
                    {{ $t("Зарегистрировать") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "RegisterEuolStatementAsDlg",
    data () {
        return {
            title: "Регистрация",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            uniqueNumber: 0
        }
    },
    computed: {
        ...mapGetters('references', ['GetReference']),
        ...mapGetters('dialogs/registerEuolStatementAs',
        { 
            visible: 'isVisible',
            loading: 'isLoading',
            mode: 'getMode',
            originStatement: 'getOriginStatement',
            valid: 'isValid'
        }),
        duplicateValue: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementAs/getDuplicateValue'];
            },
            set: function(value) {
                this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "Duplicate", value });
            }
        },
        duplicateReason: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementAs/getDuplicateReason'];
            },
            set: function(value) {
                this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "DuplicateReason", value });
            }
        },
        uniqueNumberParam: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementAs/getUniqueNumberParam'];
            },
            set: function(value)
            {
                switch (value)
                {
                    case 0:
                        this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "NeedUniqueNumber", value: false });
                        this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value: null });
                        break;
                    
                    case 1:
                        this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "NeedUniqueNumber", value: true });
                        this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value: null });
                        break;

                    case 2:
                        this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "NeedUniqueNumber", value: true });
                        this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value: "" });
                        break;
                }
            }
        },
        postUniqueNumber: {
            get: function() {
                return this.$store.getters['dialogs/registerEuolStatementAs/getPostUniqueNumber'];
            },
            set: function(value) {
                this.$store.commit('dialogs/registerEuolStatementAs/UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value });
            }
        }
    },
    methods: {
        ...mapActions('dialogs/registerEuolStatementAs', ['ok', 'cancel'])
    }
}
</script>